.ant-dropdown-menu {
  position: absolute;
  top: 20px;
  padding: 0;
  background-color: #000;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  color: #fff;
  font-family: Lato, sans-serif;
  padding: 10px 20px;
  transition-duration: 0.1s;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #000;
  color: rgba(256, 256, 256, 0.5);
}
a {
  transition: none !important;
}

.vehicle-dropdown-menu-item {
  color: #999 !important;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}

.vehicle-dropdown-menu-item:hover {
  background-color: #ddd;
  color: #003ead !important;
}
