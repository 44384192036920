@import "~antd/dist/antd.css";
@font-face {
  font-family: "Lato";
  src: url("./styles/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "LatoThin";
  src: url("./styles/fonts/Lato-Thin.ttf");
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  scroll-behavior: smooth;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  display: block;
}
a {
  background-color: transparent;
  color: #fff;
  text-decoration: none;
}

#custom-loader-el > span {
  -webkit-animation: 1s linear 0.4s infinite normal none running
      react-spinners-CircleLoader-circle,
    custom-el-border-transition 5s linear infinite !important;
  animation: 1s linear 0.4s infinite normal none running
      react-spinners-CircleLoader-circle,
    custom-el-border-transition 5s linear infinite !important;
}

@-webkit-keyframes custom-el-border-transition {
  0% {
    border-top: 1px solid white;
    border-left: 1px solid white;
  }
  33% {
    border-top: 1px solid white;
    border-left: 1px solid white;
  }
  67% {
    border-top: 1px solid green;
    border-left: 1px solid green;
  }
  100% {
    border-top: 1px solid green;
    border-left: 1px solid green;
  }
}

@keyframes custom-el-border-transition {
  0% {
    border-top: 1px solid white;
    border-left: 1px solid white;
  }
  33% {
    border-top: 1px solid green;
    border-left: 1px solid green;
  }
  67% {
    border-top: 1px solid green;
    border-left: 1px solid green;
  }
  100% {
    border-top: 1px solid white;
    border-left: 1px solid white;
  }
}

h1 {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 44px;
  font-family: Lato, sans-serif;
  color: #313131;
  font-size: 38px;
}
h2 {
  font-size: 32px;
  line-height: 36px;
  margin-top: 20px;
}
img {
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  border: 0;
}
p {
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  color: #757575;
  font-weight: 300;
}
h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Lato, sans-serif;
  color: #525252;
  font-size: 21px;
  line-height: 23px;
  font-weight: 300;
  text-align: center;
}
center {
  display: block;
  text-align: -webkit-center;
}

label {
  display: block;
  margin-bottom: 5px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 100;
}

.container-4 {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.container-5 {
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.w-container:before,
.w-container:after {
  content: " ";
  display: table;
}

.main-vehicle-row {
  margin-top: 25px;
  margin-bottom: 0px;
  display: flex;
  flex-wrap: wrap;
}
.w-row:before,
.w-row:after {
  content: " ";
  display: table;
}

.column-4 {
  padding-right: 2% !important;
}
.column-6 {
  padding-left: 0% !important;
}

.w-col-6 {
  width: 50%;
}
.w-col-2 {
  width: 16.66666667%;
}
.w-col {
  position: relative;
  /* float: left; */
  /* width: 100%; */
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.column-div.left {
  text-align: left;
}

.column-div {
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
}

.large-paragraph.gray.left {
  font-size: 16px;
  line-height: 26px;
  text-align: left;
}

.large-paragraph.gray.reviews {
  margin-bottom: 0px;
}

.large-paragraph.gray {
  color: #757575;
  text-align: center;
}

.large-paragraph {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 300;
}

.titles-div {
  position: relative;
  left: 0%;
  display: block;
  width: 60%;
  margin-right: auto;
  margin-bottom: 50px;
  margin-left: auto;
  text-align: center;
  text-transform: none;
}

.titles-div.top {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: auto;
  margin-top: 0%;
  margin-right: 0%;
  margin-left: 0%;
  padding-top: 25px;
  padding-bottom: 25px;
  background-image: none;
}
.titles-div.less-padding {
  margin-bottom: 25px;
}
.main-header {
  font-weight: 400;
}

.c1 {
  padding-right: 25px;
}

.c2 {
  padding-left: 25px;
}

.white.left {
  color: #000a1d;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  text-align: left;
}

.white {
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.tiny-text {
  float: none;
  font-family: Lato, sans-serif;
  color: #757575;
  font-size: 11px;
  line-height: 11px;
  text-align: left;
}

.heading-11 {
  margin-bottom: 50px;
  font-weight: 400;
  text-align: center;
}

.gray-link {
  color: #757575;
}

.w-form {
  margin: 0 0 15px;
}

.row-5 {
  margin-bottom: 25px;
}

.w-input,
.w-select {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.label {
  color: #757575;
  font-weight: 300;
  text-align: left;
}

.div-block-3 {
  position: relative;
  height: auto;
  margin-top: 50px;
}

.video {
  height: 250px;
}
.w-video {
  width: 100%;
  position: relative;
  padding: 0;
}

.w-video iframe,
.w-video object,
.w-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.heading-2 {
  position: relative;
  z-index: 999;
  color: #fff;
  font-weight: 300;
}

.w-hidden-main {
  display: none !important;
}

.gradient {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: auto;
  height: 135%;
  background-image: -webkit-linear-gradient(
    270deg,
    rgba(0, 0, 0, 0.89) 32%,
    transparent
  );
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.89) 32%,
    transparent
  );
}

.trusted {
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.proud-suppliers-to {
  margin-top: 50px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
}

.column-div._0 {
  display: flex;
  height: 100px;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
}

._0 {
  margin-top: 0px;
}

.image-3 {
  width: 75px;
}
.image-5 {
  width: 125px;
}

.heading-10 {
  font-weight: 400;
}

.link-block {
  width: auto;
  margin-bottom: 0px;
  padding: 0px;
  text-align: center;
  text-decoration: none;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.blue.simple {
  color: #000;
}

.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

.container-0 {
  margin: 0 10%;
  position: relative;
}

.margin-top-200 {
  margin-top: 200px;
}

.header-gap-creator {
  height: 57px;
  width: 100%;
}

.search-gap-creator {
  width: 100%;
  /* height: 108px;  */
}

@media screen and (min-width: 767px) {
  .header-gap-creator {
    height: 67px;
  }
  .search-gap-creator {
    height: 108px;
  }
}

@media screen and (max-width: 1300px) {
  .titles-div {
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .w-row,
  .w-container .w-row {
    margin-left: 0;
    margin-right: 0;
  }

  .w-col {
    width: 100% !important;
    left: auto;
    right: auto;
  }

  .titles-div {
    width: 90%;
  }
  .container-0 {
    position: relative;
    padding: 0;
  }
}
@media screen and (max-width: 479px) {
  .main-vehicle-row {
    margin-top: 25px;
    margin-bottom: 0px;
  }
  .main-header {
    font-size: 32px;
    line-height: 40px;
  }
  .titles-div.top {
    display: none;
  }

  .link-block {
    width: 100%;
    margin-top: 25px;
  }
}

.customFilters {
  display: block;
  padding: 15px;
  background-color: rgba(0, 173, 35, 0.86);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.39);
  margin: 0px auto;
  transform: translateY(-50%);
  position: absolute;
  left: 5%;
  right: 5%;
  z-index: 9;
  transition: 300ms ease;
  display: flex;
  /* max-height: 90%; */
  flex-direction: column;
  justify-content: start;
}

.productRenderContainer {
  max-height: 100%;
  margin-top: 10px;
  overflow-y: auto;
}
@media screen and (min-width: 767px) {
  .sticky {
    position: fixed;
    top: 20px;
    left: 10%;
    transform: translateY(0%);
    right: 10%;
    z-index: 99;
    background-color: rgba(0, 173, 35, 1);
  }
  .sticky > h3 {
    visibility: hidden;
  }
}

.customFilters h3 {
  font-size: 25px;
  font-weight: bold;
  color: #fff;
}

.customFilters .customSelects {
  width: 18%;
  margin: 0px 0px;
  box-sizing: border-box;
}

.selectController {
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  margin: 0px -0px;
  flex-direction: row;
}

.selectController > span {
  color: #fff;
  font-weight: bold;
  font-size: 11px;
}

.selectActions {
  margin-top: 10px;
  display: flex;
  justify-content: right;
}

button.ant-btn.ant-btn-primary.customBtns {
  background: #fff;
  color: rgba(0, 173, 35, 1);
  outline: none;
  border: none;
}

button.ant-btn.ant-btn-link.customBtns {
  color: #fff;
  font-weight: bold;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
  color: rgba(0, 173, 35, 1);
  font-size: 14px;
}

@media screen and (max-width: 767px) {
  .customFilters {
    position: relative;
    transform: none;
    left: 0px;
    right: 0px;
    margin-top: 0px;
    /* margin-bottom: -50px; */
    box-shadow: none;
  }

  .selectController {
    flex-direction: column;
  }
  .customFilters .customSelects {
    width: 100%;
    margin: 10px 0px;
    height: 40px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 40px;
  }
}

.searchedItems {
  display: flex;
  flex-direction: column;
}

.searchedItems h2 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}

.searchedProductDetails {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.searchProductLeftContainer {
  margin-right: 45px;
}
.searchedProductRight p {
  text-align: left;
  font-size: 16px;
  color: #fff;
  margin: 3px 0px;
}
.searchedProductRight a:hover {
  color: #fff;
}

.searchProductPrice {
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
}

@media only screen and (max-width: 845px) {
  .searchProductPrice {
    display: block;
    margin-top: 20px;
    margin-left: 0px;
  }
}

.searchProductPrice h5 {
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.searchProductPrice button {
  background-color: #fff;
  color: rgba(0, 173, 35, 0.86);
  cursor: pointer;
}

/* component.css */
