.top-banner {
    background-color: rgb(173, 45, 45);
    color: #333;
    padding: 10px 0px;
    text-align: center;
    margin: 66px 10% 0px;
    z-index: 99996;
  }

  .top-banner .title {
    margin-bottom: 0px;
    margin-top: 0px;
    color: #fff;
    font-size: 16px;
  }

  .top-banner p {
    margin-bottom: 0px;
    color: #d4d4d4;
    font-size: 12px;

  }
  
  .top-banner-content {
    animation: slide-in 1s ease-out;
  }
  
  @keyframes slide-in {
    0% {
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @media screen and (max-width: 767px)
  {
    .top-banner {
        padding: 0;
    }
  }